<template>
  <v-container id="clientes" tag="section">
    <base-material-card
      class="px-5 py-3"
      color="primary"
      icon="mdi-account-group"
      inline
      :title="$t('clients')"
    >
      <v-spacer></v-spacer>
      <template v-slot:after-heading></template>

      <!-- Boton nuevo registro -->
      <template v-slot:corner-button>
        <v-btn class="ma-2" color="primary" elevation="2" small @click.stop="dialog = true">
          Nuevo Cliente
        </v-btn>
      </template>

      <v-overlay :value="overlay">
        <v-progress-circular color="success" indeterminate :size="50"></v-progress-circular>
      </v-overlay>

      <!-- Texfield para la barra de búsqueda -->
      <v-text-field
        append-icon="mdi-magnify"
        class="ml-auto"
        hide-details
        label="Búsqueda"
        single-line
        style="max-width: 250px"
        v-model="search"
      />

      <!-- Dialogo Cancelar -->
      <ConfirmationDialog
        buttonColor1="error"
        buttonText1="NO"
        buttonColor2="primary"
        buttonText2="SI"
        text="Seguro quiere cancelar?"
        title="¡Se perderan todos los cambios!"
        v-model="isSure"
        :width="420"
        @click="cancel"
      />

      <!-- Dialogo Agregar/Editar Cliente -->
      <v-dialog max-width="600px" no-click-animation persistent v-model="dialog">
        <v-card>
          <!-- <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title> -->

          <v-card-text>
            <base-subheading :subheading="formTitle" />
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      class="pa-0"
                      label="Nombre(s)*"
                      required
                      v-model="editedItem.nombre"
                      :error-messages="nombreErrors"
                      @blur="$v.editedItem.nombre.$touch()"
                      @change="onFormChangeHandler"
                      @input="$v.editedItem.nombre.$reset()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      class="pa-0"
                      label="Apellido(s)*"
                      required
                      v-model="editedItem.apellido"
                      :error-messages="apellidoErrors"
                      @blur="$v.editedItem.apellido.$touch()"
                      @change="onFormChangeHandler"
                      @input="$v.editedItem.apellido.$reset()"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-select
                      class="pa-0"
                      dense
                      hint="Seleciona el Género"
                      item-text="genero"
                      item-value="_id"
                      label="Género"
                      persistent-hint
                      return-object
                      v-model="editedItem.genero"
                      :items="generos"
                      :menu-props="{ top: true, offsetY: true }"
                      @change="onFormChangeHandler"
                    >
                      {{ editedItem.generos }}
                    </v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      class="pa-0"
                      dense
                      hint="Seleciona el País"
                      item-text="pais"
                      item-value="_id"
                      label="País"
                      persistent-hint
                      return-object
                      v-model="editedItem.pais"
                      :items="paises"
                      :menu-props="{ top: true, offsetY: true }"
                      @change="onFormChangeHandler"
                    >
                      {{ editedItem.paises }}
                    </v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      class="pa-0"
                      dense
                      hint="Seleciona el Idioma"
                      item-text="idioma"
                      item-value="_id"
                      label="Idioma"
                      persistent-hint
                      return-object
                      v-model="editedItem.idioma"
                      :items="idiomas"
                      :menu-props="{ top: true, offsetY: true }"
                      @change="onFormChangeHandler"
                    >
                      {{ editedItem.idiomas }}
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="7">
                    <v-text-field
                      class="pa-0"
                      label="Correo Electrónico*"
                      required
                      v-model="editedItem.correo"
                      :error-messages="correoErrors"
                      @blur="$v.editedItem.correo.$touch()"
                      @change="onFormChangeHandler"
                      @input="$v.editedItem.correo.$reset()"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      class="pa-0"
                      counter
                      label="Teléfono"
                      maxlength="13"
                      v-model="editedItem.contacto.telefono"
                      :error-messages="telefonoErrors"
                      @blur="$v.editedItem.contacto.telefono.$touch()"
                      @change="onFormChangeHandler"
                      @input="$v.editedItem.contacto.telefono.$reset()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      class="pa-0"
                      counter
                      label="Celular*"
                      maxlength="13"
                      required
                      v-model="editedItem.contacto.celular"
                      :error-messages="celularErrors"
                      @blur="$v.editedItem.contacto.celular.$touch()"
                      @change="onFormChangeHandler"
                      @input="$v.editedItem.contacto.celular.$reset()"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row><small class="pl-4">*Campo Requerido</small></v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <!-- Botones y CheckList -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-checkbox
              label="Crear otro"
              v-if="editedIndex === -1"
              v-model="checkbox"
              :disabled="isEditing"
            ></v-checkbox>

            <!-- Botones de guardar y cancelar -->
            <v-btn color="error" text @click="checkChanges">Cancelar</v-btn>
            <v-btn color="primary" text @click="save" :disabled="$v.$invalid">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Termina Dialogo Agregar/Editar Cliente -->

      <v-divider class="mt-3" />

      <!-- Table de datos de Clientes -->
      <v-data-table
        dense
        loading-text="Cargando... Espere por favor"
        :headers="headers"
        :items="clientes"
        :loading="isLoading"
        :search.sync="search"
      >
        <!-- <template v-slot:[`item.nombre`]="{ item }">
          <div class="name-info-title">{{ item.nombre }} {{ item.apellido }}</div>
          <div class="mail-info-subtitle">
            <v-icon class="mr-2" small>mdi-send</v-icon>
            <a v-bind:href="'mailto:' + item.correo">{{ item.correo }}</a>
          </div>
        </template> -->
        <!-- Slot del cliente -->
        <template v-slot:[`item.nombre`]="{ item }">
          <UserInfo :nombre="item.nombre" :apellidos="item.apellido" :correo="item.correo" />
        </template>
        <!-- Slot del contacto -->
        <!-- <template v-slot:[`item.contacto`]="{ item }">
          <div>
            <div class="name-info-title">
              <v-icon class="mr-2" small>mdi-deskphone</v-icon>{{ item.contacto.telefono }}
            </div>
            <div class="name-info-title">
              <v-icon class="mr-2" small>mdi-cellphone</v-icon>{{ item.contacto.celular }}
            </div>
          </div>
        </template> -->
        <template v-slot:[`item.contacto`]="{ item }">
          <ContactInfo :tel="item.contacto.telefono" :cel="item.contacto.celular" />
        </template>

        <!-- Slot del genero -->
        <template v-slot:[`item.genero`]="{ item }">
          <div class="name-info-title">{{ item.genero }}</div>
        </template>

        <!-- Slot del pais -->
        <template v-slot:[`item.pais`]="{ item }">
          <div class="name-info-title">{{ item.pais }}</div>
        </template>

        <!-- Slot del idioma -->
        <template v-slot:[`item.idioma`]="{ item }">
          <div class="name-info-title">{{ item.idioma }}</div>
        </template>

        <!-- Slot del boton Registrado-->
        <template v-slot:[`item.registrado`]="{ item }">
          <Status :activo="item.registrado" txtTrue="Si" txtFalse="No" />
        </template>

        <!-- Slot del boton Activo-->
        <template v-slot:[`item.activo`]="{ item }">
          <Status :activo="item.activo" />
        </template>

        <!-- Slot de Acciones-->
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-2" small @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>

        <template v-slot:no-data>
          <!-- <v-btn color="primary">Reset</v-btn> -->
          ¡No hay datos para mostrar!
        </template>
      </v-data-table>

      <!-- DataExporter -->
      <DataExporter :dataArray="clientes" :dataFields="fields" fileName="Clientes" />
    </base-material-card>
  </v-container>
</template>

<script>
import { email, maxLength, minLength, numeric, required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

import ConfirmationDialog from '@/components/general/ConfirmationDialog.vue'
import ContactInfo from '@/components/general/ContactInfo.vue'
import DataExporter from '@/components/general/DataExporter.vue'
import Status from '@/components/general/StatusInfo.vue'
import UserInfo from '@/components/general/UserInfo.vue'

export default {
  name: 'Clientes',
  components: {
    ConfirmationDialog,
    ContactInfo,
    DataExporter,
    Status,
    UserInfo,
  },
  data: () => ({
    changed: false,
    checkbox: false,
    dialog: false,
    editedIndex: -1,
    isEditing: false,
    isLoading: false,
    isSure: false,
    notificacelular: false,
    notificaemail: false,
    overlay: false,
    search: undefined,
    tipo: '',

    clientes: [],
    generos: [],
    headers: [
      { text: 'Cliente', value: 'nombre', align: 'start', sortable: true },
      { text: 'Contacto', value: 'contacto', sortable: false },
      { text: 'Genero', value: 'genero.genero', sortable: true },
      { text: 'Pais', value: 'pais.pais', sortable: true },
      { text: 'Idioma', value: 'idioma.idioma', sortable: true },
      { text: 'Registrado', value: 'registrado', sortable: true },
      { text: 'Activo', value: 'activo' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    idiomas: [],
    paises: [],
    value: [],

    defaultItem: {
      _id: '',
      nombre: '',
      apellido: '',
      genero: '',
      pais: '',
      idioma: '',
      categoria: '',
      correo: '',
      contacto: { telefono: '', celular: '' },
      registrado: false,
      activo: true,
    },
    editedItem: {
      _id: '',
      nombre: '',
      apellido: '',
      genero: '',
      pais: '',
      idioma: '',
      categoria: '',
      correo: '',
      contacto: { telefono: '', celular: '' },
      registrado: false,
      activo: true,
    },

    fields: {
      Nombre: 'nombre',
      Apellido: 'apellido',
      Telefono: 'contacto.telefono',
      Celular: 'contacto.celular',
      Genero: 'genero.genero',
      Pais: 'pais.pais',
      Idioma: 'idioma.idioma',
    },
  }),

  mixins: [validationMixin],
  validations: {
    editedItem: {
      nombre: { required, minLength: minLength(1), maxLength: maxLength(80) },
      apellido: { required, minLength: minLength(1), maxLength: maxLength(80) },
      correo: { required, minLength: minLength(1), maxLength: maxLength(80), email },
      contacto: {
        celular: { required, minLength: minLength(10), maxLength: maxLength(13), numeric },
        telefono: { minLength: minLength(10), maxLength: maxLength(13), numeric },
      },
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo Cliente' : 'Editar Cliente'
    },

    nombreErrors() {
      const errors = []
      if (!this.$v.editedItem.nombre.$dirty) return errors
      !this.$v.editedItem.nombre.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.nombre.minLength &&
        errors.push('Este campo debe de tener un mínimo de 1 caracter.')
      !this.$v.editedItem.nombre.maxLength &&
        errors.push('Este campo debe de tener un máximo de 80 caracteres.')
      return errors
    },
    apellidoErrors() {
      const errors = []
      if (!this.$v.editedItem.apellido.$dirty) return errors
      !this.$v.editedItem.apellido.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.apellido.minLength &&
        errors.push('Este campo debe de tener un mínimo de 1 caracter.')
      !this.$v.editedItem.apellido.maxLength &&
        errors.push('Este campo debe de tener un máximo de 80 caracteres.')
      return errors
    },
    correoErrors() {
      const errors = []
      if (!this.$v.editedItem.correo.$dirty) return errors
      !this.$v.editedItem.correo.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.correo.email && errors.push('Escriba un Correo Válido')
      !this.$v.editedItem.correo.minLength &&
        errors.push('Este campo debe de tener un mínimo de 1 caracter.')
      !this.$v.editedItem.correo.maxLength &&
        errors.push('Este campo debe de tener un máximo de 80 caracteres.')
      return errors
    },
    celularErrors() {
      const errors = []
      if (!this.$v.editedItem.contacto.celular.$dirty) return errors
      !this.$v.editedItem.contacto.celular.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.contacto.celular.numeric && errors.push('Solo se aceptan números')
      !this.$v.editedItem.contacto.celular.minLength &&
        errors.push('Este campo debe de tener un mínimo de 10 digitos.')
      !this.$v.editedItem.contacto.celular.maxLength &&
        errors.push('Este campo debe de tener un máximo de 13 digitos.')
      return errors
    },
    telefonoErrors() {
      const errors = []
      if (!this.$v.editedItem.contacto.telefono.$dirty) return errors
      !this.$v.editedItem.contacto.telefono.numeric && errors.push('Solo se aceptan números')
      !this.$v.editedItem.contacto.telefono.minLength &&
        errors.push('Este campo debe de tener un mínimo de 10 digitos.')
      !this.$v.editedItem.contacto.telefono.maxLength &&
        errors.push('Este campo debe de tener un máximo de 13 digitos.')
      return errors
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  created() {
    this.obtenerGeneros()
    this.obtenerIdiomas()
    this.obtenerPaises()
    this.obtenerClientes()
  },

  methods: {
    /**
     * @description Elimina un registro de la base de datos y del data table
     */
    async BorrarRegistro() {
      this.overlay = true
      this.$v.$touch()

      let payload = {}
      payload.id = this.editedItem._id
      this.$store
        .dispatch('client/deleteClient', payload)
        .then(() => {
          this.$store.commit('ALERT', {
            color: 'success',
            text: 'El registro se elimino con éxito',
          })
          this.overlay = false
        })
        .catch((err) => {
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.msg,
          })
          this.overlay = false
        })
      this.editedItem = Object.assign({}, this.defaultItem)
    },

    /**
     * @description confirma la cancelacion de la edicion en el formulario de mantenimiento
     * de clientes
     */
    cancel(isSure) {
      this.isSure = isSure
      if (this.isSure) {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Revisa si hay cambios en el formulario de mantenimiento de
     * clientes
     */
    checkChanges() {
      if (this.changed) {
        this.isSure = true
      } else {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Cierra el diálogo de mantenimiento de clientes
     */
    close(checkbox) {
      this.isSure = false
      this.changed = false
      this.checkbox = checkbox
      this.isEditing = false
      if (!this.checkbox) {
        this.dialog = false
      }

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$v.$reset()
    },

    /**
     * @description elimina un registro de la tabla y de la base de datos
     */
    async deleteItem(item) {
      const index = this.clientes.indexOf(item)
      this.editedItem = Object.assign({}, item)
      if (confirm('Esta seguro que desea eliminar este registro?')) {
        this.BorrarRegistro()
        this.clientes.splice(index, 1)
      }
    },

    /**
     * @description prepara un registro para su edición y muestra el dialogo
     * para editarlo
     */
    editItem(item) {
      this.isEditing = true
      this.editedIndex = this.clientes.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    /**
     * @description Obtiene un listado de todos los clientes
     */
    async obtenerClientes() {
      this.isLoading = true
      let payload = {}
      this.$store
        .dispatch('client/fetchClients', payload)
        .then((response) => {
          this.clientes = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
            // Enviar a inicio de sesión
          })
        })
    },

    /**
     * @description Obtiene un listado de todas los generos
     */
    async obtenerGeneros() {
      this.isLoading = true
      let payload = {}
      this.$store
        .dispatch('gender/fetchGenders', payload)
        .then((response) => {
          this.generos = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
          })
        })
    },

    /**
     * @description Obtiene un listado de todas los idiomas
     */
    async obtenerIdiomas() {
      this.isLoading = true

      let payload = {}
      this.$store
        .dispatch('languaje/fetchLanguajes', payload)
        .then((response) => {
          this.idiomas = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
            // Enviar a inicio de sesión
          })
        })
    },

    /**
     * @description Obtiene un listado de todas las paises
     */
    async obtenerPaises() {
      this.isLoading = true
      let payload = {}
      this.$store
        .dispatch('country/fetchCountries', payload)
        .then((response) => {
          this.paises = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
          })
        })
    },

    /**
     * @description Marca que ha cambiado el valor de un campo en el formulario
     */
    onFormChangeHandler() {
      this.changed = true
    },

    /**
     * @description Guarda el contenido del formulario ya sea para editar un ciente o
     * crear uno nuevo
     */
    async save() {
      this.overlay = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        let body = {
          nombre: this.editedItem.nombre,
          apellido: this.editedItem.apellido,
          correo: this.editedItem.correo,
          contacto: this.editedItem.contacto,
          genero: this.editedItem.genero._id,
          pais: this.editedItem.pais._id,
          idioma: this.editedItem.idioma._id,
          tipo: '5f10a141f0ebe712df0663f4',
        }
        let payload = { body: body }
        if (this.editedIndex > -1) {
          payload.id = this.editedItem._id
          this.$store
            .dispatch('client/editClient', payload)
            .then(() => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se actualizó con éxito',
              })
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
              })
              this.overlay = false
            })
          Object.assign(this.clientes[this.editedIndex], this.editedItem)
        } else {
          this.$store
            .dispatch('client/addClient', payload)
            .then((response) => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se creó con éxito',
              })
              this.clientes.push(response.data)
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
              })
              this.overlay = false
            })
        }
        this.close(this.checkbox)
      }
    },
  },
}
</script>
